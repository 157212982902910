<template>
  <div class="container">
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h3 class="card-title">Tv Schedule</h3>
            <h6>(The following schedule is subject to change!)</h6>
          </template>
          <template v-slot:body>
            <div class="d-block">
              <select class="form-control form-control-lg" @change="changedate($event)">
                <option
                  v-for="date in scheduleList.keys()"
                  :value="date.id"
                  :key="date.id"
                >
                  {{ date }}
                </option>
              </select>
              <br /><br />
              <ul class="list-unstyled">
                <b-media
                  class="p-2"
                  tag="li"
                  v-for="show in shows"
                  :key="show.id"
                  vertical-align="center"
                >
                  <template v-slot:aside>
                    <!-- <b-img :src="require('@/assets/images/page-img/24.jpg')" class="mr-3 avatar-80" alt="#"></b-img> -->
                    <div id="parent text-center">
                      <h5 class="mt-0 ">
                        {{
                          new Date(show.time).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit"
                          })
                        }}
                      </h5>
                      <h6 class="mt-0">
                        {{
                          new Date(show.time)
                            .toLocaleTimeString("en-us", {
                              timeZoneName: "short"
                            })
                            .split(" ")[2]
                        }}
                      </h6>
                    </div>
                  </template>
                  <b-media-body>
                    <h4 class="mt-0 text-primary">{{ show.title }}</h4>
                    <p class="mb-0">{{ show.description }}</p>
                  </b-media-body>
                </b-media>
              </ul>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: 'ScheduleList',
  props: {
    scheduleList: Map
  },
  data: function() {
    return {
      shows: [],
      selecteddate: null
    }
  },
  methods: {
    changedate(event) {
      this.selecteddate =
        event.target.options[event.target.options.selectedIndex].text
      this.shows = this.scheduleList.get(this.selecteddate)
    }
  },
  watch: {
    scheduleList: function(newVal, oldVal) {
      this.selecteddate = newVal.keys().next().value
      this.shows = this.scheduleList.get(this.selecteddate)
    }
  }
}
</script>

<style scoped>
select.form-control{
    -webkit-appearance: menulist!important;
    -moz-appearance: menulist!important;
    -ms-appearance: menulist!important;
    -o-appearance: menulist!important;
    appearance: menulist!important;
}
</style>
