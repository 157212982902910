<template>
	<main id="main" class="site-main">
		<Schedule :scheduleList="scheduleList" />
	</main>
</template>
<script>
import Schedule from "./Components/NLSE/Schedule";

export default {
	name: "LiveSchedule",
	components: {
		Schedule,
	},
	data() {
		return {
			scheduleList: new Map(),
		};
	},
	async created() {
		// 	{
		// 		schedules(where: { date_gte: "2021-07-15" }) {
		// 			time
		// 			date
		// 			title
		// 			description
		// 		}
		// 	}
		// `

		this.scheduleList = new Map();
		var dateOptions = {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
			timeZone: "UTC",
		};
		for (let i = 0; i < data.schedules.length; i++) {
			var dateKey = new Date(data.schedules[i].date).toLocaleDateString(
				"en-US",
				dateOptions
			);
			if (this.scheduleList.has(dateKey)) {
				var sched = this.scheduleList.get(dateKey);
				sched.push(data.schedules[i]);
				this.scheduleList.set(dateKey, sched);
			} else {
				this.scheduleList.set(dateKey, [data.schedules[i]]);
			}
		}
	},
};
</script>
